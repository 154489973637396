<!--  -->
<template>
  <div class="this-insurance-mall">
    <div class="allHead">
      <div class="head">
        <div class="top">
          <div class="backimage" @click="back()">
            <img src="../../assets/image/back.png" />
          </div>
          <p>保险商城</p>
        </div>
        <!-- 搜索框 -->
        <div class="search">
          <div class="ipt">
            <img src="../../assets/image/sousuo.png" alt />
            <input
              type="text"
              v-model="shopName"
              placeholder="请输入关键词进行搜索"
              @input="search"
              @focus="search"
            />
            <img
              class="clears"
              v-if="isclear"
              src="../../assets/image/cleans.png"
              @click="clearAll"
            />
          </div>
        </div>
      </div>
      <div class="type" v-if="isshow">
        <van-dropdown-menu active-color="#47a3ff">
          <van-dropdown-item
            v-model="value1"
            title-class="coms"
            :options="option1"
            @change="componey"
          />
          <van-dropdown-item v-model="value2" @change="int" title-class="baos" :options="option2" />
          <van-dropdown-item
            v-model="value3"
            @change="sanint"
            title-class="sans"
            :options="option3"
          />
        </van-dropdown-menu>
      </div>
    </div>
    <!-- 保险类型选择 -->
    <div class="content">
      <!-- 筛选 -->
      <div class="over">
        <div class="screen" v-if="isshow">
          <ul>
            <li @click="newest()" :style="{background:(changeColor?'#f4f4f4':'#47a3ff')}">
              <div :style="{color:(changeColor?'#666':'#fff')}">最新上架</div>
            </li>
            <!-- <li>销量优先</li> -->
            <li @click="lowest()" :style="{background:(changeColor?'#47a3ff':'#f4f4f4')}">
              <div :style="{color:(changeColor?'#fff':'#666')}">价格最低</div>
            </li>
          </ul>
        </div>
        <!-- 保险列表 -->
        <!--        -->
        <div class="Insurance">
          <div class="recommend-items" v-if="insList.length>0">
            <div
              class="recommend-item"
              v-for="(item,index) in insList"
              :key="index"
              @click="tl(item.bxShopCompanyId,item.bxShopId,item.standby1,item.bxShopName,item.standby2,item.bxShopUrl)"
              :style="{borderBottom:(index ==insList.length-1?'0':'1px solid #f5f5f5')} "
            >
              <div class="rec-left">
                <img :src="item.bxShopImage" />
              </div>
              <div class="rec-right">
                <div class="rec-top">
                  <span class="det-title">{{item.bxShopName}}</span>
                  <img
                    class="rec-img"
                    src="../../assets/image/tuijian.png"
                    v-if="item.bxShopHot==0"
                  />
                </div>
                <div class="rec-bottom">
                  <div class="univalent">
                    <span class="a">￥</span>
                    <span>{{item.bxShopPrice}}</span>
                    <span>起</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty" v-if="insList.length<=0">
            <van-empty description="暂无信息" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Axios from 'axios'
import { DropdownMenu, DropdownItem, Field, Toast, Dialog } from 'vant'
import { mapState, mapActions } from 'vuex'
import { Empty } from 'vant'

Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Empty)
Vue.use(Toast)
Vue.use(Field)
Vue.use(Dialog)
export default {
  data () {
    return {
      changeColor: false,
      value1: 0,
      value2: 0,
      value3: 0,
      option1: [{
        text: '公司',
        value: 0
      }],
      option2: [{
        text: '分类',
        value: 0
      }],
      option3: [{
        text: '保险',
        value: 0
      }],
      insList: [],
      shopName: '',//关键字搜索
      time: '',//最新上架时间
      istime: true,
      isclear: false,
      isshow: true,


    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {
    this.companyList()
    this.insuranceSlect()
    this.value1 = parseInt(this.$store.state.InsuranceMall.value1)
    this.value2 = parseInt(this.$store.state.InsuranceMall.value2)
    this.value3 = parseInt(this.$store.state.InsuranceMall.value3)
    this.istime = this.$store.state.InsuranceMall.istime
    this.inst()

    if (this.value2 != 0) {
      this.insuranceMallChilder({
        bxShopTypeParentId: this.value2
      })
        .then(res => {
          if (res.code == 200) {
            this.option3 = res.data
            if (res.data.length >= 0) {
              this.value3 = parseInt(this.$store.state.InsuranceMall.value3)
            }
            if (this.istime == true) {
              this.newest()
            } else {
              this.lowest()
            }
          }
        })
    }


  },
  beforeUpdate () {
    if (this.value1 != 0) {
      document.querySelector('.coms .van-ellipsis').style.color = "#47a3ff"
    }
    if (this.value2 != 0) {
      document.querySelector('.baos .van-ellipsis').style.color = "#47a3ff"
    }
    if (this.value3 != 0) {
      document.querySelector('.sans .van-ellipsis').style.color = "#47a3ff"
    }
  },
  methods: {
    ...mapActions('insuranceMall', ['insuranceMall', 'insuranceMallChilder', 'company', 'toLink']),
    back () {
      window.history.go(-1)

    },
    // 完成
    sanint () {
      this.$store.commit('setInsuranceMall2', { value3: this.value3 })
      document.querySelector('.sans .van-ellipsis').style.color = "#47a3ff"
      if (this.istime == true) {
        this.newest()

      } else {
        this.lowest()
      }
    },
    componey () {
      this.$store.commit('setInsuranceMall', { value1: this.value1 })
      document.querySelector('.coms .van-ellipsis').style.color = "#47a3ff"
      if (this.istime == true) {
        this.newest()

      } else {
        this.lowest()
      }
    },
    tl (bxShopCompanyId, bxShopId, standby1, shopName, standby2, bxShopUrl) {
      if (standby2 == '0') {
        window.location.href = bxShopUrl

      } else {
        const listLink = {
          cid: bxShopCompanyId,
          pid: bxShopId,
          pcode: standby1,
          pname: shopName
        }
        if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
          this.toLink(listLink).then(res => {
            if (res.code == 200) {
              window.location.href = res.msg
            } else {
              Toast(res.msg)
            }

          })
        } else {
          Dialog.confirm({
            title: '系统提示',
            message: '请登录后操作!',
            confirmButtonText: '确定',
            confirmButtonColor: "#5d6d8e",
            showCancelButton: false,
            className: 'tishi'
          }).then(() => {
            this.$router.push({
              name: 'Login'
            })

          })
        }

      }



    },
    //公司列表下拉
    companyList (id) {
      this.company()
        .then((res) => {
          var opt = [{
            text: '公司',
            value: 0
          }]
          this.option1 = opt.concat(res.data)

        })
    },
    insuranceSlect () { // 父列表
      this.insuranceMall()
        .then((res) => {
          if (res.code == 200) {
            var pot2 = [{
              text: '分类',
              value: 0
            }]
            this.option2 = pot2.concat(res.data)
            if (this.option2.length > 0) {
              document.querySelector('.baos .van-ellipsis').style.color = "#323233"
            }
          }

        })
    },

    ...mapActions('insuranceMall', ['insuranceshopList']),
    inst () {//保险列表
      if (this.istime == true) {
        this.newest()
      } else {
        this.lowest()
      }
    },
    int (id) {//联动子接口
      this.$store.commit('setInsuranceMall1', { value2: this.value2 })
      if (id != 0) {
        document.querySelector('.baos .van-ellipsis').style.color = "#47a3ff"
        this.insuranceMallChilder({
          bxShopTypeParentId: id
        })
          .then(res => {
            if (res.code == 200) {
              this.option3 = res.data
              if (res.data.length > 0) {
                this.value3 = res.data[0].value
                this.$store.commit('setInsuranceMall2', { value3: this.value3 })
              }
              if (this.istime == true) {
                this.newest()
              } else {
                this.lowest()
              }
            }
          })

      } else {
        this.option3 = [{
          text: '保险',
          value: 0
        }]
        this.value3 = 0
        if (this.istime == true) {
          this.newest()
        } else {
          this.lowest()
        }

      }

      //获取子类id刷新页面

    },
    clearAll () {
      this.shopName = ''
      this.isclear = false
      if (this.istime == true) {
        this.newest()
      } else {
        this.lowest()
      }

    },
    search () {
      if (this.shopName != '') {
        this.isclear = true
      } else {
        this.isclear = false
      }
      if (this.istime == true) {
        this.newest()
      } else {
        this.lowest()
      }
    },
    //  最新上架
    newest () {

      this.changeColor = false

      this.istime = true
      this.$store.commit('setInsuranceMall4', this.istime)
      this.insuranceshopList({
        bxShopHot: 0,
        bxShopCompanyId: this.value1,
        bxShopParentId: this.value2,
        bxShopChildId: this.value3,
        bxShopName: this.shopName
      }).then(res => {
        if (res.code == 200) {
          this.insList = res.rows

        }

      })


    },
    //   价格最低
    lowest () {
      this.changeColor = true

      this.istime = false
      this.$store.commit('setInsuranceMall4', this.istime)
      this.insuranceshopList({
        bxShopPrice: 0,
        bxShopHot: 1,
        bxShopCompanyId: this.value1,
        bxShopName: this.shopName,
        bxShopParentId: this.value2,
        bxShopChildId: this.value3,
      }).then(res => {
        if (res.code == 200) {
          this.insList = res.rows
        }
      })
    },

  },

  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
};
</script>
<style scoped>
  @import '../../../public/common.css';
</style>
<style scoped>
.a{
  margin-top: 0.06rem;
}
  .this-insurance-mall {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    /* height:100%; */
    font-size: 0.3rem;
    overflow: hidden;
    /* display: flex;
    flex-direction: column; */
  }
  .allHead{
    height:auto;
    position: fixed;
    top:0;
    left:0;
    z-index:100000;
    width:100%;
  }
  .head {
    height: 2.1rem;
    width: 100%;
    background-color: #fff;
    background: url(../../assets/image/beijing.png) no-repeat;
    background-size:100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    flex-shrink: 0;

  }

  .uls {
    display: flex;
    justify-content: space-around;
    height: 1rem;
    line-height: 1rem;
    color: #ffffff;
  }

  .uls img {
    width: 10%;
    margin-left: -0.05rem;
    margin-top: 0.4rem;
  }

  .uls li {
    width: 30%;
    padding-left: 0.5rem;
    box-sizing: border-box;
  }

  /* 搜索 */
  .search .ipt {
    width: 95%;
    height: 0.7rem;
    font-size: 0.28rem;
    background: rgba(255,255,255,0.45);
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    margin:0 auto;
  }

  .search .ipt img {
    width: 0.28rem;
    height: 0.28rem;
    margin-left: 0.45rem;
    margin-right: 0.12rem;
    flex-shrink: 0;
  }

  .search .ipt input {
    /* opacity: 0.2; */
    font-size: 0.3rem;
    width: 100%;
    color: #ffffff;

    box-sizing: border-box;
    background: rgba(255, 255, 255, 0);
  }

  /* 保险类型选择 */
  .content {
    width: 100%;
    height:100%;
      display: flex;
    flex-direction: column;
    padding-top:3.1rem;
    background: #fff;

  }

  .content .type {
    height: 1rem;
    font-family: PingFang-SC-Bold;
    color: #333333;
    flex-shrink: 0;
  }

  .type  .van-dropdown-menu__bar {
    box-shadow: 0 0 0 0;
  }

  .type .van-dropdown-menu__title::after {
    position: absolute;
    top: 70%;
    right: -4px;
    margin-top: -5px;
    border: 3px solid;
    border-color: transparent transparent #999999 #999999;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: .8;
    content: '';
  }

  /* 保险筛选 */
  .content .screen {
    height: auto;
    width: 95%;
    margin-left: 0.18rem;
    font-family: PingFang-SC-Regular;
    display: block;
    flex-shrink: 0;
    padding-bottom: 0.4rem;
  }

  .content .screen ul {
    display: flex;
    justify-content: space-around;
    color: #333333;
  }

  .content .screen ul li {

    /* line-height: 1rem; */
    /* border:1px solid red; */
    text-align: center;
    background: #f4f4f4;
    color: #666;
    padding: 0.12rem 0.38rem;
    font-size: 0.26rem;
    border-radius: 0.04rem;
  }

  /* 保险列表 */
  .Insurance {
    width:90%;
    margin:0 auto;

  }
  .over{
    height:100%;
   /* overflow: hidden; */

     overflow-y:auto ;
    -webkit-overflow-scrolling: touch;


  }

  .InsuranceList {
    width: 95%;
    position: relative;
    line-height: 2.3rem;
    height: 2.3rem;
    border-bottom: 1px solid #dfdad6;
    margin-left: 0.2rem;
    /* padding-top:-0.5rem; */
    box-sizing: border-box;
    margin-top: 0.3rem;
  }

  .InsuranceList .tuijian {
    width: 10%;
    position: absolute;
    right: 0.42rem;
    top: 0;
  }

  .InsuranceList div {
    width: 90%;
    height: 2rem;
    margin-left: 0.3rem;
    display: flex;
    /* margin-top: -0.1rem; */
    box-sizing: border-box;
    /* border: 1px solid red; */
    /* justify-content: space-between; */
  }

  .InsuranceList div img {
    width: 32%;
  }

  .InsuranceList div ul {
    margin-left: 0.3rem;
  }

  .InsuranceList div ul li {
    height: 1rem;
    line-height: 1rem;
    font-family: PingFang-SC-Bold;
    font-weight: 550;
    color: #666666;
  }

  .InsuranceList div ul .jiagou ol {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .InsuranceList div ul .jiagou ol li {
    color: #f5591b;

  }

  .InsuranceList div ul .jiagou ol img {
    width: 15%;
    position: absolute;
    top: 0.22rem;
    /* right: 0.1rem; */
  }
  .top{
    background: none;
    position: relative;
    background: (255,255,255,0);
  }
  /* 修改商城ckk */
    .recommend-item {
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 0.4rem;
    display: flex;
    margin-bottom: 0.4rem

  }
  .rec-left {
    width: 33%;
    height: 1.6rem;
    border-radius: 0.08rem;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 3%;
  }

  .rec-left img {
    height: 100%;
    width: 100%;
    display: block;
  }
  .rec-right{
        width: 64%;
  }
  .rec-det{
    width: 100%;
    display: flex;
  }

  .rec-img{
    width: 0.66rem;
    height:0.66rem;
    display: block;
    flex-shrink: 0;

  }
    .insure-Info, .rec-top {
    display: flex;
    justify-content: space-between;
  }
 .det-title {
   font-family: PingFang-SC-Bold;
	font-size: 0.3rem;
	font-stretch: normal;
	line-height: 0.36rem;
	letter-spacing: 0rem;
	color: #666666;
    margin-top:0.2rem;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    width:100%
  }
  .rec-bottom {
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-top:0.3rem
  }
.univalent {
    display: flex;
    height: auto;
    align-items: baseline;
  }

  .univalent span:nth-child(1) {
    font-size: 0.3rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #f5591b;
    font-family: PingFang-SC-Bold;

  }

  .univalent span:nth-child(2) {
    font-size: 0.38rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #f5591b;
    font-family: PingFang-SC-Bold;
    /* margin-top:0.04rem */

  }

  .univalent span:nth-child(3) {
    font-size: 0.24rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #f5591b;
    /* margin-top: 0.08rem; */
    margin-left: 0.02rem;
    font-family: PingFang-SC-Bold;
  }
  .empty{
    margin-top:1rem
  }
  .clears{
    width:0.28rem;
    height:0.27rem;
    flex-shrink: 0;
    margin-left:0.25rem !important;
    margin-right:0.3rem !important;
    display: block;
  }
</style>
